import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bots"
    }}>{`Bots`}</h1>
    <h2 {...{
      "id": "team-bots"
    }}>{`Team Bots`}</h2>
    <p>{`Team bots follow a predefined path, attack the opponent guardians and try to capture the pole of the other team.
They spawn each 30 seconds and are quite weak but become stronger as the game progress.
When a player eliminates an opponent's bot, he gains 7 to 15 `}<a parentName="p" {...{
        "href": "/players#coppers"
      }}>{`coppers`}</a>{` (7 in the beginning of the game, 15 in late game).`}</p>
    <h2 {...{
      "id": "guardians"
    }}>{`Guardians`}</h2>
    <p><img alt="A guardian" src={require("../public/guardian.jpg")} /></p>
    <p style={{
      "textAlign": "center",
      "fontSize": "0.8em",
      "marginTop": "-25px"
    }}><em>A Blue Guardian</em></p>
    <p>{`Guardians are strong bots who live in a mini-tower and never move. You must kill all enemy's guardians to be allowed to capture their pole.
Guardians are invincible before the first bots wave and to shots over 25 meters. Players can't deal more than one damage to a guardian at each hit. If a player kills a guardian, he gains 30 `}<a parentName="p" {...{
        "href": "/players#coppers"
      }}>{`coppers`}</a>{`. Each team has 9 guardians.`}</p>
    <h2 {...{
      "id": "gold-bots"
    }}>{`Gold Bots`}</h2>
    <p>{`Gold Bots are very precious bots. They live at the center of the map and are neutral: they attack all players and all bots except others Gold Bots.
If a player kills a Gold Bot, he gains 20 `}<a parentName="p" {...{
        "href": "/players#coppers"
      }}>{`coppers`}</a>{` and his teammates gains 15 coppers.
A Gold Bot respawns 1 minute after his elimination and becomes a little stronger.`}</p>
    <p><img alt="A guardian" src={require("../public/goldbot.jpg")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      